<template>
  <div>
    <el-card>
      <common-user-center-title :title="'推广订单'"></common-user-center-title>
      <div class="title-option">
        <el-tabs v-model="activeName" @tab-click="topSwitchClick">
          <el-tab-pane label="自推订单" name="1"></el-tab-pane>
          <el-tab-pane label="团队订单" name="2"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="order-center">
        <el-row class="search">
          <el-col :span="16">
            <el-select size="small" class="fl" clearable v-model="orderQueryInfo.time" placeholder="请选择时间">
              <el-option
                v-for="item in orderQueryInfoTimeList"
                :key="item.id"
                :label="item.text"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-input size="small" v-model="orderQueryInfo.id" class="fr" clearable placeholder="请输入订单号">
              <template #append>
                <el-button icon="el-icon-search"/>
              </template>
            </el-input>
          </el-col>
        </el-row>
        <div class="order-list">
          <div class="order-item" v-for="item in orderList" :key="item.id">
            <div class="order-title">
              <div class="order-info">
                <div class="order-status">
                  <div v-if="item.status_code === 'paid'">未付款</div>
                  <div v-else-if="item.status_code === 'delivery'">待发货</div>
                  <div v-else-if="item.status_code === 'delivered'">已发货</div>
                  <div v-else-if="item.status_code === 'received'">已收货</div>
                </div>
                <div class="order-no">订单号：{{item.no}}</div>
              </div>
              <div class="order-option">
                <div class="distribute-info">下单人：{{item.nickname}}</div>
                <div class="distribute-info">佣金：<span>￥{{item.distributeMoney}}</span></div>
              </div>
            </div>
            <div class="product-list">
              <div class="product-item">
                <div class="product-img">
                  <img v-lazy="item.productImg" />
                </div>
                <div class="product-info">
                  <div class="product-name">{{item.productName}}</div>
                  <div class="d-f">
                    <div class="product-price">￥{{item.productPrice}}
                      <span>*{{item.amount}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页区域 -->
        <el-pagination
          :current-page="orderQueryInfo.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="orderQueryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { getDistributionOrderListUrl } from '@/api/index'
export default {
  name: 'DistributionOrders',
  components: {
    commonUserCenterTitle
  },
  data () {
    return {
      activeName: '1',
      orderQueryInfo: {
        time: '',
        id: '',
        pageSize:10,
        page: 1,
        type: 1,
      },
      orderQueryInfoTimeList: [
        { id: 1, text: '全部时间' },
        { id: 2, text: '今天' },
        { id: 3, text: '3天内' },
        { id: 4, text: '一周内' },
        { id: 5, text: '一月内' },
      ],
      orderList: [],
      total: 0,
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getDistributionOrdersList()
    },
    // 获取推广订单
    async getDistributionOrdersList () {
      const { data: res } = await this.$http.get(getDistributionOrderListUrl,{params:this.orderQueryInfo})
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.orderList = res.data.list
      this.total = res.data.total
    },
    // 分页页码显示条数
    handleSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.orderQueryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getDistributionOrdersList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.orderQueryInfo.page = newPage
      // 重新获取数据并显示
      this.getDistributionOrdersList()
    },
    // 订单状态切换
    topSwitchClick () {
      switch (this.activeName) {
        case '1':
          this.orderQueryInfo.type = 1
          this.orderQueryInfo.page = 1
          this.getDistributionOrdersList()
          break
        case '2':
          this.orderQueryInfo.type = 2
          this.orderQueryInfo.page = 1
          this.getDistributionOrdersList()
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
  .title-option {
    position: absolute;
    top: 14px;
    left: 120px;

    .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
  }
  .order-list {
    margin-top: 20px;

    .order-item {
      border: 1px solid #d3d3d3;
      font-size: 14px;
      margin-bottom: 20px;

      .order-title {
        padding: 10px 20px;
        white-space: nowrap;
        border-bottom: 1px solid #d3d3d3;
        display: flex;
        justify-content: space-between;

        .order-info {
          .order-status {
            font-size: 16px;
            margin-bottom: 4px;
          }

          .order-no {
            font-size: 12px;
            color: #999999;

            span {
              margin-left: 30px;
            }
          }
        }
        .order-option{
          .distribute-info{
            font-size: 12px;
            color: #666666;
            height: 22px;
            line-height: 22px;
            span{
              color: #EF001C;
            }
          }
        }

      }

      .product-list {
        padding: 20px 20px 10px;

        .product-item {
          margin-bottom: 10px;
          display: flex;
          justify-content: start;

          .product-img {
            img {
              width: 66px;
              height: 66px;
            }
          }

          .product-info {
            margin-left: 20px;
            width: 750px;

            .product-name {
              width: 100%;
              height: 42px;
              white-space: inherit;
              text-overflow: inherit;
            }

            .product-price {
              color: #f42424;
              margin-right: 5px;
              font-weight: 100;

              span {
                margin-left: 4px;
                color: #999999;
                margin-right: 30px;
              }
            }
          }
        }
      }
    }
  }
</style>
